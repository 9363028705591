$headings-font-family: "Righteous", cursive;
$body-bg: #D83D52;
$body-color: #FFF;
$primary: #F4DC71;
$font-family-base: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1.3rem;
$h1-font-size: $font-size-base * 2.5;
$card-columns-count: 3;
$gray-600: #6c757d;
$secondary: #B13243;
$text-muted: #B13243;
$link-hover-decoration: none;
$dark: #061934;
