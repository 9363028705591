body {
	overflow-x: hidden;
}
section {
	overflow-y: hidden;
}
.w-30 {
	width: 30% !important;
}
.check {
	width: 30px;
	margin-right: 10px;
}
.section-frase {
	position: relative;
	padding-top: 5vh;
	padding-bottom: 5vh;
}
.bg-gray {
	background: #F9F9F9;
}
.team-member {
	border: 6px solid #FFF;
	transition: transform .5s ease;
}
.team-member:hover {
  transform: scale(1.1);
}
.division-top {
	position: absolute;
	top: -1px;
}
.division-bottom {
	position: absolute;
	bottom: -1px;
}
.container-fluid {
	padding: 0px;  
}
.section-home .container h1,
.section-frase .container h1 {
	font-size: 300%;
}
.section-home .container {
	padding-top: 10vh;
	height: 75vh;
}
.section-home .container p.lead,
.section-frase .container p.lead {
	font-size: 80%;
	line-height: 125%;
}
.section-banner {
	margin-top: -44vh;
}
.section-ofrecimiento {
	padding-top: 3rem;
}
.section-ofrecimiento a {
	color: #061934;
}
.section-experiencia {
	padding-top: 4rem;
}

.whatsapp {
	position:fixed;
	width:60px;
	height:60px;
	bottom:90px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	font-size:34px;
	z-index:100;
}

.whatsapp:hover {
	color:#FFF;
}

.whatsapp-icon {
	margin-top:13px;
}

@include media-breakpoint-up(sm) {
	.section-home .container h1,
	.section-frase .container h1 {
		font-size: 400%;
	}
	.section-home .container {
		padding-top: 15vh;
		height: 80vh;
	}
	.section-home .container p.lead,
	.section-frase .container p.lead {
		font-size: 110%;
	}
	.section-frase {
		padding-top: 15vh;
		padding-bottom: 15vh;
	}
}
@include media-breakpoint-up(md) {
	.section-home .container h1 {
		font-size: 600%;
	}
	.section-home .container {
		padding-top: 18vh;
		height: 65vh;
	}
}